let ErrorTypesCC = {
  API_GENERIC: 0,
  TOKEN_EXPIRED: 1,
  UNAUTHORISED: 2,
  TOKEN_INVALID_RESET_PASSWORD: 3
};


function handleUserErrorsCC(errorType, contentToReplace) {
  removeUserError(contentToReplace);  //should a previous error be presetn, remove it first
  let errorContent;

  switch (errorType) {
    case ErrorTypesCC.API_GENERIC:
    default:
      errorContent = `
        <img height="100px" src="./../images/illustrations/error_illustration.svg" class="mb-2 question-header-illustration" />
        <p>Uh oh! Something went wrong on our end</p>
        <div class="smaller-black-text">
          <p>Please refresh the page and try again</p>
        </div>`;
      break;
    case ErrorTypesCC.TOKEN_EXPIRED:
      errorContent = `
          <img height="100px" src="./../images/illustrations/error_illustration2.svg" class="mb-2 question-header-illustration" />
          <p>Your session has expired</p>
          <div class="smaller-black-text">
            <p>Please log in again</p>
            <button type="button" class="submit centered ripple" onclick="goToLogin()">Go to login</button></p>
          </div>`;
      break;
    case ErrorTypesCC.UNAUTHORISED:
      errorContent = `
          <img height="100px" src="./../images/illustrations/error_illustration2.svg" class="mb-2 question-header-illustration" />
          <p>You are not authorised to see this page</p>
          <div class="smaller-black-text">
            <p>Please log in again</p>
            <button type="button" class="submit centered ripple" onclick="goToLogin()">Go to login</button></p>
          </div>`;
      break;
    case ErrorTypesCC.TOKEN_INVALID_RESET_PASSWORD:
      errorContent = `
          <img height="100px" src="./../images/illustrations/error_illustration2.svg" class="mb-2 question-header-illustration" />
          <p>This link is invalid or has expired</p>
          <div class="smaller-black-text">
            <button type="button" class="submit centered ripple" onclick="goToForgotPassword()">Request a new one</button></p>
          </div>`;
      break;
  }

  $('.loader').removeClass('show-loader');
  $(contentToReplace).hide();
  $('#screener-container').append(`<div class="text-center mx-auto" id="fatalErrorContainer">${errorContent}</div>`);
  $('#fixed-bottom-bar').addClass('d-none');
}

function removeUserError(contentToReplace) {
  $('#fatalErrorContainer').remove();
  $(contentToReplace).show();
  $('#questions').show();
  $('#fixed-bottom-bar').removeClass('d-none');
}

function goToLogin() {
  sessionStorage.clear();
  window.location = window.location.origin + "/callcenter/login";
}

function goToForgotPassword() {
  window.location = window.location.origin + "/callcenter/forgotPassword";
}